import { Button, Flex, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FINISH, useCreateCampaignStepsContext } from '../campaign-create-steps-context'
import { Title } from '../title'

export function FinishStep() {
    const { t } = useTranslation()
    const { currentStep, match, improve } = useCreateCampaignStepsContext()

    return (
        <div className={classNames({ hidden: currentStep !== FINISH })}>
            <Title>{t('Finish')}</Title>
            <Flex
                vertical
                gap={40}
            >
                <div className="text-center">
                    <Typography.Title>{(match?.matchScore || 0) >= 70 ? '🏆' : '😐'}</Typography.Title>
                    <Typography.Title
                        level={2}
                        className="m-0"
                    >
                        {match?.matchScore}%
                    </Typography.Title>
                </div>
                {match?.positiveCandidateAttributes && match?.positiveCandidateAttributes.length > 0 && (
                    <div>
                        <ol className="flex gap-1 flex-col">
                            {match?.positiveCandidateAttributes.map((attribute) => (
                                <li className="flex gap-2">
                                    <span>✅</span> <span>{attribute}</span>
                                </li>
                            ))}
                        </ol>
                    </div>
                )}
                {match?.negativeCandidateAttributes && match?.negativeCandidateAttributes.length > 0 && (
                    <div>
                        <ol className="flex gap-1 flex-col">
                            {match?.negativeCandidateAttributes.map((attribute) => (
                                <li className="flex gap-2">
                                    <span>➖</span> <span>{attribute}</span>
                                </li>
                            ))}
                        </ol>
                    </div>
                )}
                <Flex
                    gap="large"
                    justify="space-between"
                >
                    <Button
                        size="large"
                        onClick={() => improve()}
                    >
                        {t('I want to improve further')}
                    </Button>
                    <Button
                        htmlType="submit"
                        size="large"
                        type="primary"
                    >
                        {t('Start campaign')}
                    </Button>
                </Flex>
            </Flex>
        </div>
    )
}
