import { createFileRoute } from '@tanstack/react-router'
import React from 'react'

export const Route = createFileRoute('/_auth/_main/campaigns/$campaignId/overview')({
    component: CampaignOverview,
})

function CampaignOverview() {
    return <></>
}
