import { LoadingOutlined } from '@ant-design/icons'
import { createFileRoute } from '@tanstack/react-router'
import { Flex, Result, Spin } from 'antd'
import React from 'react'
import { ViewCampaign } from '../components/view-campaign'
import { useTranslation } from 'react-i18next'
import { useCampaign } from '../campaign/hooks'

export const Route = createFileRoute('/_auth/_main/campaigns/$campaignId')({
    component: ShowCampaign,
})

function ShowCampaign() {
    const { t } = useTranslation()
    const { campaignId } = Route.useParams()

    const campaign = useCampaign(campaignId)

    if (campaign.isPending) {
        return (
            <Flex
                align="center"
                className="w-full h-96"
                justify="center"
            >
                <Spin
                    indicator={<LoadingOutlined spin />}
                    size="large"
                />
            </Flex>
        )
    }

    if (campaign.isError) {
        return (
            <Result
                status="error"
                title={t('There are some problems with your operation.')}
            />
        )
    }

    // if (campaign.data.status === 'training') {
    //     return (
    //         <CreateCampaign
    //             initialStep={1}
    //             initialCampaign={campaign.data}
    //         />
    //     )
    // }

    return <ViewCampaign campaign={campaign.data} />
}
