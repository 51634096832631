import { createFileRoute, Outlet } from '@tanstack/react-router'
import React from 'react'
import { SimpleLayout } from '../components/layout/simple-layout'

export const Route = createFileRoute('/_auth/_simple')({
    component: AuthMain,
})

export function AuthMain() {
    return (
        <SimpleLayout>
            <Outlet />
        </SimpleLayout>
    )
}
