import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEnUS from './locales/en_US/translation.json'
import translationNlNL from './locales/nl_NL/translation.json'
import { defaultLocale } from './auth'

const resources = {
    en_US: {
        translation: translationEnUS,
    },
    nl_NL: {
        translation: translationNlNL,
    },
}

i18n.use(initReactI18next).init({
    resources,
    lng: defaultLocale() || 'nl_NL',
    fallbackLng: 'nl_NL',
    interpolation: {
        escapeValue: false,
    },
})

i18n.services.formatter?.add('lower', (value) => {
    return value.toLowerCase()
})
