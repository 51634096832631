import React from 'react'
import { Form, FormItemProps } from 'antd'

export function FormItem<Values>({ rules, ...props }: FormItemProps<Values>) {
    const fakeRule = { validator: () => Promise.resolve() }
    const _rules = [fakeRule, ...(rules || [])]
    return (
        <Form.Item
            {...props}
            rules={_rules}
        />
    )
}
