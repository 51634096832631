import { useQuery } from '@tanstack/react-query'
import { search } from './api'

export function useSearch(query?: string) {
    return useQuery({
        queryFn: () => search(encodeURIComponent(query!)),
        queryKey: ['search', query],
        enabled: !!query,
    })
}
