import { Flex, Typography } from 'antd'
import React from 'react'

export type MessageRole = 'user' | 'assistant'
export type Message = {
    role: MessageRole
    content: string
}

export function ChatMessage({ role, content }: Message) {
    const color = role === 'assistant' ? 'bg-[#CC33CC66] rounded-bl-md' : 'bg-[#E0E0E0] rounded-br-md'

    return (
        <Flex
            align={role === 'user' ? 'end' : 'start'}
            vertical
        >
            <div className={`shadow p-4 rounded-3xl ${color} max-w-[60%]`}>
                <Typography.Text className="mr-1 text-[#141414] leading-5">{content}</Typography.Text>
                {/* <Typography.Text className="text-[10px] " type="secondary">
          10:38
        </Typography.Text> */}
            </div>
        </Flex>
    )
}
