import { Button, Popconfirm } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '../../user'
import { useBlockUser, useUnblockUser } from '../../hooks'
import { useAuth } from '../../../auth'

export type UserBlockButtonProps = {
    user: Pick<User, 'id' | 'blockedAt'>
}
export function UserBlockButton({ user }: UserBlockButtonProps) {
    const { t } = useTranslation()
    const blockMutation = useBlockUser(user.id)
    const unblockMutation = useUnblockUser(user.id)
    const { is } = useAuth()

    const mutation = user.blockedAt ? unblockMutation : blockMutation

    return (
        <Popconfirm
            onConfirm={() => mutation.mutateAsync()}
            title={t('Are you sure?')}
            okText={t('Yes')}
            cancelText={t('No')}
            okButtonProps={{
                danger: true,
                loading: mutation.isPending,
            }}
        >
            <Button
                danger
                disabled={is(user)}
            >
                {user.blockedAt ? t('Restore access') : t('Block access')}
            </Button>
        </Popconfirm>
    )
}
