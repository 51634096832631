import { Radio, RadioGroupProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { locales } from '../locale'

export function LocaleRadio(props: RadioGroupProps) {
    const { t } = useTranslation()
    return (
        <Radio.Group
            size="large"
            {...props}
        >
            {locales.map((locale) => {
                return <Radio.Button value={locale}>{t(`locales.${locale}`)}</Radio.Button>
            })}
        </Radio.Group>
    )
}
