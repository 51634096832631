import { createFileRoute, Link, redirect, useNavigate } from '@tanstack/react-router'
import { Button, Flex, Form, Input, Typography } from 'antd'
import React from 'react'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { useTranslation } from 'react-i18next'
import { useUpdateUser, useUser } from '../user/hooks'
import { UPDATE_USER } from '../user/permission'
import { FormItem } from '../components/form-item'
import useForm from '../hooks/use-form'
import { LocaleRadio } from '../user/components/locale-radio'
import { RoleRadio } from '../user/components/role-radio'
import { NetworkErrors } from '../components/network-errors'
import { UserLink } from '../user/components/user-link'
import { UpdateInput } from '../user/input/update-input'
import { useAuth } from '../auth'

export const Route = createFileRoute('/_auth/_main/users/$userId/edit')({
    beforeLoad: ({ context }) => {
        if (!context.auth.can(UPDATE_USER)) {
            throw redirect({
                to: '/dashboard',
            })
        }
    },
    component: EditUser,
})

function EditUser() {
    const { t } = useTranslation()
    const params = Route.useParams()
    const mutation = useUpdateUser(params.userId)

    const navigate = useNavigate()
    const query = useUser(params.userId)

    const { is } = useAuth()

    const formProps = useForm({
        mutation,
        onSuccess(user) {
            navigate({
                to: `/users/${user.id}`,
            })
        },
    })

    if (!query.isSuccess) {
        return <NetworkErrors query={query} />
    }

    const user = query.data

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: <Link to="/users">{t('Users')}</Link>,
                    },
                    {
                        title: (
                            <UserLink
                                id={user.id}
                                name={user.name}
                            />
                        ),
                    },
                    {
                        title: t('Edit'),
                    },
                ]}
            />
            <Flex
                justify="space-between"
                align="center"
                className="mb-4"
            >
                <Typography.Title
                    level={4}
                    className="mb-0"
                >
                    {t('Edit {{name}}', { name: t('User') })}
                </Typography.Title>
            </Flex>
            <Form
                layout="vertical"
                className="max-w-96"
                initialValues={user}
                {...formProps}
            >
                <FormItem<UpdateInput>
                    label={t('Role')}
                    name="role"
                    required
                >
                    <RoleRadio disabled={is(user)} />
                </FormItem>

                <FormItem<UpdateInput>
                    label={t('Name')}
                    name="name"
                    required
                >
                    <Input
                        name="name"
                        placeholder="John Doe"
                    />
                </FormItem>

                <FormItem<UpdateInput>
                    label={t('Language')}
                    name="locale"
                    required
                >
                    <LocaleRadio />
                </FormItem>
                <Button
                    loading={mutation.isPending}
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    {t('Save')}
                </Button>
            </Form>
        </>
    )
}
