import { post } from '../axios'
import { analyzeJobOfferResult } from './output/analyze-job-offer-result'
import { AnalyzeJobOfferInput } from './input/analyze-job-offer-input'
import { AssistantMessageInput } from './input/assistant-message-input'
import { sendNextMessageResult } from './output/send-next-message-result'
import { stopAndRateConversationResult } from './output/stop-and-rate-conversation-result'

export const analyzeJobOffer = (input: AnalyzeJobOfferInput) => {
    return post(analyzeJobOfferResult, `/prompts/analyze-job-offer`, input)
}

export const assistantMessage = (input: AssistantMessageInput) => {
    return post(sendNextMessageResult.or(stopAndRateConversationResult), `/prompts/assistant-message`, input)
}
