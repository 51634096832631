import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import { Layout, Flex } from 'antd'
import React from 'react'
import { z } from 'zod'

const fallback = '/dashboard' as const

export const Route = createFileRoute('/_guest')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    beforeLoad: ({ context, search }) => {
        if (context.auth.isAuthenticated) {
            throw redirect({ to: search.redirect || fallback })
        }
    },
    component: GuestComponent,
})

function GuestComponent() {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout.Content>
                <Flex
                    align="center"
                    justify="center"
                    vertical
                    className="h-screen w-screen"
                >
                    <Outlet />
                </Flex>
            </Layout.Content>
        </Layout>
    )
}
