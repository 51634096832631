import { List, theme } from 'antd'
import React from 'react'
import { SearchResponse } from '../../api'
import { SearchProps } from '../../components/search'
import { useTranslation } from 'react-i18next'
import { CampaignSearchListItem } from './campaign-search-list-item'

export type CampaignSearchListProps = {
    campaigns: SearchResponse['campaigns']
} & Pick<SearchProps, 'onCancel'>

export function CampaignSearchList({ campaigns, onCancel }: CampaignSearchListProps) {
    const { token } = theme.useToken()
    const { t } = useTranslation()

    return (
        <List
            header={<span style={{ padding: `0 ${token.paddingMD}px` }}>{t('Campaigns')}</span>}
            dataSource={campaigns}
            renderItem={(campaign) => {
                return (
                    <CampaignSearchListItem
                        onClick={onCancel}
                        campaign={campaign}
                    />
                )
            }}
        />
    )
}
