import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Link, Outlet, useMatchRoute, useNavigate } from '@tanstack/react-router'
import { Switch, Tooltip } from 'antd'
import React, { useEffect } from 'react'
import { Campaign } from '../zod/campaign'
import { Container } from './layout/container'
import { PageHeader } from './page-header'
import { PortaledBreadcrumb } from './portaled-breadcrumb'
import { useUpdateCampaign } from '../campaign/hooks'
import { useTranslation } from 'react-i18next'

export type CampaignViewProps = {
    campaign: Campaign
}

export function ViewCampaign({ campaign }: CampaignViewProps) {
    const navigate = useNavigate()
    const matchRoute = useMatchRoute()

    const { t } = useTranslation()
    const updateCampaign = useUpdateCampaign(campaign.id)

    useEffect(() => {
        if (matchRoute({ to: '/campaigns/$campaignId' }) !== false) {
            navigate({
                to: '/campaigns/$campaignId/overview',
                params: { campaignId: campaign.id },
                replace: true,
            })
        }
    }, [campaign, matchRoute, navigate])

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: <Link to="/campaigns">{t('Campaigns')}</Link>,
                    },
                    {
                        title: campaign.title,
                    },
                ]}
            />
            <PageHeader
                title={campaign.title}
                onTitleChanged={(title) => {
                    updateCampaign.mutate({
                        title,
                    })
                }}
            >
                <Tooltip title={campaign.status === 'active' ? 'Pause conversations' : 'Resume conversations'}>
                    <Switch
                        onChange={() => {
                            updateCampaign.mutate({
                                status: campaign.status === 'active' ? 'inactive' : 'active',
                            })
                        }}
                        loading={updateCampaign.isPending}
                        checked={campaign.status === 'active'}
                        checkedChildren={<PauseOutlined />}
                        unCheckedChildren={<PlayCircleOutlined />}
                    />
                </Tooltip>
            </PageHeader>

            <Container className="py-6">
                <Outlet />
            </Container>
        </>
    )
}
