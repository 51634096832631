import { useNavigate } from '@tanstack/react-router'
import { Avatar, Table, Typography } from 'antd'
import React from 'react'
import { UsePaginationTableProps } from '../../hooks/use-pagination'
import { TimeAgo } from '../../components/time-ago'
import { User } from '../user'
import { RoleTag } from './role-tag'
import { UserLink } from './user-link'

export function UserTable({ withColumnDefaults, ...props }: UsePaginationTableProps<User>) {
    const navigate = useNavigate()

    return (
        <Table
            {...props}
            rowClassName="cursor-pointer"
            onRow={(record) => {
                return {
                    onClick: () => {
                        navigate({
                            to: '/users/$userId',
                            params: { userId: record.id },
                        })
                    },
                }
            }}
            columns={withColumnDefaults([
                {
                    title: '',
                    dataIndex: 'id',
                    width: 60,
                    key: 'id',
                    render(text) {
                        return <Avatar src={'https://api.dicebear.com/7.x/miniavs/svg?seed=' + text} />
                    },
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role',
                    width: 100,
                    render(role) {
                        return <RoleTag role={role} />
                    },
                    sorter: true,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    sorter: true,
                    render(name, { id }) {
                        return (
                            <UserLink
                                id={id}
                                name={name}
                            />
                        )
                    },
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    width: '30%',
                    render(email) {
                        return <Typography.Link href={`mailto:${email}`}>{email}</Typography.Link>
                    },
                },
                {
                    title: 'Created',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    width: 150,
                    render(date) {
                        return <TimeAgo date={date} />
                    },
                    sorter: true,
                },
            ])}
        />
    )
}
