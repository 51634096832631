import { List, theme } from 'antd'
import { RoleTag } from './role-tag'
import React from 'react'
import { SearchResponse } from '../../api'
import { SearchProps } from '../../components/search'
import { UserAvatar } from './user-avatar'
import { UserLink } from './user-link'
import { useTranslation } from 'react-i18next'
import { BlockedTag } from './blocked-tag'

export type UserSearchListProps = {
    users: SearchResponse['users']
} & Pick<SearchProps, 'onCancel'>

export function UserSearchList({ users, onCancel }: UserSearchListProps) {
    const { token } = theme.useToken()
    const { t } = useTranslation()

    return (
        <List
            header={<span style={{ padding: `0 ${token.paddingMD}px` }}>{t('Users')}</span>}
            dataSource={users}
            renderItem={(user) => {
                return (
                    <UserLink
                        id={user.id}
                        onClick={onCancel}
                    >
                        <List.Item
                            tabIndex={0}
                            style={{
                                padding: `${token.paddingSM}px ${token.paddingMD}px`,
                            }}
                            className="cursor-pointer"
                            extra={
                                <>
                                    {user.blockedAt && <BlockedTag />}
                                    <RoleTag role={user.role}></RoleTag>
                                </>
                            }
                        >
                            <List.Item.Meta
                                avatar={<UserAvatar user={user} />}
                                title={user.name}
                                description={user.email}
                            />
                        </List.Item>
                    </UserLink>
                )
            }}
        />
    )
}
