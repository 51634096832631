import { Link } from '@tanstack/react-router'
import { Avatar, Flex, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Conversation = {
    name: string
    compatibility: number
}

export function ConversationCard({ name, compatibility }: Conversation) {
    const { t } = useTranslation()
    let type = 'negative'

    if (compatibility >= 50) {
        type = 'suspect'
    }

    if (compatibility >= 70) {
        type = 'positive'
    }

    return (
        <Link
            to="/campaigns/$campaignId/$type/$conversationId"
            params={{
                type,
                campaignId: '0650720f-21e8-4c62-9ae2-4af9862aa34d',
                conversationId: '7ce6a5d7-30fc-4c79-bdb7-feb341e008f7',
            }}
            className="text-left hover:bg-[#f4effa] rounded-lg p-2"
        >
            <Flex gap="small">
                <Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />
                <div>
                    <Typography.Text className="mb-0 block">{name}</Typography.Text>
                    <Typography.Text type="secondary">
                        {t('Compatibility')} {compatibility}% {compatibility >= 70 && <>🏆</>}
                    </Typography.Text>
                </div>
            </Flex>
        </Link>
    )
}
