import { Radio, RadioGroupProps } from 'antd'
import React from 'react'
import { roles } from '../role'
import { RoleTag } from './role-tag'

export function RoleRadio(props: RadioGroupProps) {
    return (
        <Radio.Group
            size="large"
            {...props}
        >
            {roles.map((role) => {
                return (
                    <Radio value={role}>
                        <RoleTag role={role} />
                    </Radio>
                )
            })}
        </Radio.Group>
    )
}
