import { ArrowRightOutlined, BellOutlined, SettingOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { createFileRoute, Link } from '@tanstack/react-router'
import { Avatar, Button, Card, Flex, List, theme, Typography } from 'antd'
import React from 'react'
import { Container } from '../components/layout/container'
import { PageHeader } from '../components/page-header'
import { useTranslation } from 'react-i18next'
import { UserAvatar } from '../user/components/user-avatar'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { NetworkErrors } from '../components/network-errors'
import { useUser } from '../user/hooks'
import { UserBlockButton } from '../user/components/buttons/user-block-button'
import { UserForgotButton } from '../user/components/buttons/user-forgot-button'
import { BlockedTag } from '../user/components/blocked-tag'
import { RoleTag } from '../user/components/role-tag'

export const Route = createFileRoute('/_auth/_main/users/$userId/')({
    component: ShowUser,
})

function ShowUser() {
    const { t } = useTranslation()
    const { userId } = Route.useParams()
    const query = useUser(userId)
    const { token } = theme.useToken()

    if (query.isPending || query.isError) {
        return (
            <NetworkErrors
                query={query}
                result404={{
                    title: t('User not found'),
                    extra: (
                        <Link to="/users">
                            <Button type="primary">{t('Users')}</Button>
                        </Link>
                    ),
                }}
            />
        )
    }

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: <Link to="/users">{t('Users')}</Link>,
                    },
                    {
                        title: query.data.name,
                    },
                ]}
            />
            <PageHeader
                prefix={<UserAvatar user={query.data} />}
                title={
                    <div className="flex items-center gap-4">
                        {query.data.name} {query.data.blockedAt && <BlockedTag className="m-0" />}
                        <RoleTag
                            className="m-0"
                            role={query.data.role}
                        />
                    </div>
                }
            />
            <Container className="py-6">
                <Flex
                    align="start"
                    gap="middle"
                >
                    <Card className="shadow-sm w-5/12">
                        <Flex justify="space-between">
                            <Flex
                                gap="small"
                                style={{ marginBottom: token.marginSM }}
                            >
                                <BellOutlined className="text-xl" />
                                <Typography.Title
                                    className="mb-0"
                                    level={5}
                                >
                                    {t('Activities')}
                                </Typography.Title>
                            </Flex>

                            <Link to="/campaigns">
                                {t('View all')} <ArrowRightOutlined />
                            </Link>
                        </Flex>
                        <List
                            itemLayout="horizontal"
                            dataSource={[
                                {
                                    title: t('Candidate contacted'),
                                    description: 'Jamie Schouten',
                                    avatar: <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=jamie" />,
                                },
                                {
                                    title: t('Campaign Started'),
                                    avatar: <WhatsAppOutlined />,
                                },
                                {
                                    title: t('Candidate added'),
                                    description: 'Jamie Schouten',
                                    avatar: <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=jamie" />,
                                },
                                {
                                    title: t('Campaign created'),
                                    avatar: <WhatsAppOutlined />,
                                },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={item.avatar}
                                        title={<a href="https://ant.design">{item.title}</a>}
                                        description={item.description}
                                    />
                                    <Typography.Text type="secondary">9 days ago</Typography.Text>
                                </List.Item>
                            )}
                        />
                    </Card>
                    <Flex
                        vertical
                        className="w-7/12"
                        gap="middle"
                    >
                        <Card className="shadow-sm">
                            <Flex
                                gap="small"
                                style={{ marginBottom: token.marginSM }}
                            >
                                <SettingOutlined className="text-xl" />
                                <Typography.Title
                                    className="mb-0"
                                    level={5}
                                >
                                    {t('Options')}
                                </Typography.Title>
                            </Flex>
                            <Flex
                                gap="middle"
                                vertical
                            >
                                <Flex gap="middle">
                                    <UserBlockButton user={query.data} />
                                    <UserForgotButton user={query.data} />
                                </Flex>
                                <Flex gap="middle">
                                    <Link
                                        to="/users/$userId/edit"
                                        params={{ userId }}
                                    >
                                        <Button>{t('Edit')}</Button>
                                    </Link>
                                </Flex>
                            </Flex>
                        </Card>
                    </Flex>
                </Flex>
            </Container>
        </>
    )
}
