import { z } from 'zod'
import { user } from './user'

export const loginResponse = z.tuple([z.string(), z.string()])

export const claims = z.object({
    iss: z.string(),
    sub: z.string(),
    aud: z.string(),
    exp: z.number(),
})

export const accessClaims = z
    .object({
        user,
    })
    .and(claims)

export type LoginResponse = z.infer<typeof loginResponse>
export type Claims = z.infer<typeof claims>
export type AccessClaims = z.infer<typeof accessClaims>
