import { z } from 'zod'
import { get } from './axios'
import { role } from './user/role'
import { status } from './campaign/status'

export const searchResponse = z.object({
    users: z
        .object({
            id: z.string(),
            name: z.string(),
            email: z.string(),
            role,
            emailVerifiedAt: z.coerce.date().nullable(),
            blockedAt: z.coerce.date().nullable(),
            createdAt: z.coerce.date(),
            updatedAt: z.coerce.date(),
        })
        .array()
        .optional(),
    campaigns: z
        .object({
            id: z.string(),
            title: z.string(),
            status: status,
            userId: z.string(),
            createdAt: z.coerce.date(),
            updatedAt: z.coerce.date(),
        })
        .array()
        .optional(),
})

export type CampaignSearchResponse = NonNullable<SearchResponse['campaigns']>[0]
export type SearchResponse = z.infer<typeof searchResponse>

export const search = (query: string) => {
    return get(searchResponse, `/search/${query}`)
}
