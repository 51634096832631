import { Tag, TagProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function BlockedTag(props: TagProps) {
    const { t } = useTranslation()
    return (
        <Tag
            color="red"
            {...props}
        >
            {t('Blocked')}
        </Tag>
    )
}
