import { createFileRoute, Link, useNavigate, useRouter } from '@tanstack/react-router'
import { Button, Card, Checkbox, Form, Input, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { z } from 'zod'
import { useAuth } from '../auth'
import { FormItem } from '../components/form-item'
import useForm from '../hooks/use-form'
import { useTranslation } from 'react-i18next'
import { AuthenticateInput } from '../user/input/authenticate-input'

const { Text, Title } = Typography

const fallback = '/dashboard' as const

export const Route = createFileRoute('/_guest/login')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    component: LoginComponent,
})

function LoginComponent() {
    const { t } = useTranslation()
    const search = Route.useSearch()
    const router = useRouter()
    const navigate = useNavigate({ from: '/login' })
    const { login } = useAuth()

    const formProps = useForm({
        mutation: login,
        onSuccess: () => {
            // ??????????????????????????????????????
            setTimeout(() => {
                router.invalidate().finally(() => {
                    return navigate({ to: search.redirect || fallback })
                })
            }, 0)
        },
    })

    const [remember, setRemember] = useState(import.meta.env.DEV)

    return (
        <Card>
            <div className="mb-6">
                <Title level={2}>{t('Sign in')}</Title>
            </div>
            <Form
                name="basic"
                layout="vertical"
                style={{ minWidth: 300 }}
                initialValues={
                    import.meta.env.DEV
                        ? {
                              email: 'j4mie@hey.com',
                              password: 'secret secret',
                              remember,
                          }
                        : {}
                }
                autoComplete="off"
                {...formProps}
            >
                <FormItem<AuthenticateInput>
                    label={t('Email')}
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder="hello@evertising.agency"
                    />
                </FormItem>

                <FormItem<AuthenticateInput>
                    label={t('Password')}
                    name="password"
                    required
                >
                    <Input.Password
                        name="password"
                        placeholder="Password"
                    />
                </FormItem>

                <FormItem>
                    <FormItem<AuthenticateInput>
                        name="remember"
                        valuePropName="checked"
                        noStyle
                    >
                        <Tooltip
                            title={
                                remember
                                    ? t('You will be logged out after 14 days of inactivity')
                                    : t('You will be logged out after 5 minutes of inactivity')
                            }
                        >
                            <Checkbox
                                name="remember"
                                checked={remember}
                                onChange={() => setRemember(!remember)}
                            >
                                {t('Remember me')}
                            </Checkbox>
                        </Tooltip>
                    </FormItem>
                    <Link
                        className="float-right text-primary"
                        to="/forgot"
                    >
                        {t('Forgot password?')}
                    </Link>
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        loading={login.isPending}
                        block
                        type="primary"
                        htmlType="submit"
                        size="large"
                    >
                        {t('Sign in')}
                    </Button>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t("Don't have an account?")}</Text>
                        <Link
                            className="text-primary"
                            to="/"
                        >
                            {t('Sign up now')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
