// context/MyContext.tsx
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { StepProps } from 'antd'
import useForm, { UseFormReturn } from '../../../hooks/use-form'
import { useStoreCampaign } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { AnalyzeJobOfferResult } from '../../../prompts/output/analyze-job-offer-result'
import { useAnalyzeJobOffer, useAssistantMessage } from '../../../prompts/hooks'
import { useAuth } from '../../../auth'
import { Message } from '../../../components/chat/message'
import { StopAndRateConversationResult } from '../../../prompts/output/stop-and-rate-conversation-result'
import { useBlocker } from '@tanstack/react-router'

export const JOB_POSTING = 'job-posting'
export const TRY = 'try'
export const FINISH = 'finish'

export const campaignCreateSteps = [JOB_POSTING, TRY, FINISH] as const
export const campaignCreateStep = z.enum(campaignCreateSteps)

export type CampaignCreateStep = z.infer<typeof campaignCreateStep>

export type CampaignCreateStepsContextProps = {
    formProps: UseFormReturn<ReturnType<typeof useStoreCampaign>>
    currentStep: CampaignCreateStep
    current: number
    nextStep: () => void
    previousStep: () => void
    openStep: (step: number) => void
    steps: (StepProps & { key: CampaignCreateStep })[]
    analyzeJobOfferResult?: AnalyzeJobOfferResult
    isAnalyzing: boolean
    analyze: (feedback?: string) => Promise<void>
    sendAssistantMessage: (message?: string) => Promise<void>
    isSendingAssistantMessage: boolean
    match: StopAndRateConversationResult | undefined
    messages: Message[]
    improve: () => void
    tryOut: boolean
    setTryOut: Dispatch<SetStateAction<boolean>>
    unlock: () => void
}

const CampaignCreateStepsContext = createContext<CampaignCreateStepsContextProps | undefined>(undefined)

export type CampaignCreateStepsProviderProps = {
    children: ReactNode
    current?: number
    openStep: (step: number) => void
}

export const CampaignCreateStepsContextProvider = ({ children, ...rest }: CampaignCreateStepsProviderProps) => {
    const mutation = useStoreCampaign()
    const { t } = useTranslation()

    const { user } = useAuth()
    const [analyzeJobOfferResult, setAnalyzeJobOfferResult] = useState<AnalyzeJobOfferResult>()
    const [feedback, setFeedback] = useState<string[]>([])
    const [messages, setMessages] = useState<Message[]>([])
    const [match, setMatch] = useState<StopAndRateConversationResult>()
    const [tryOut, setTryOut] = useState(false)
    const [locked, setLocked] = useState(true)
    const analyzeJobOfferMutation = useAnalyzeJobOffer()
    const assistantMessageMutation = useAssistantMessage()

    const formProps = useForm({
        mutation,
        notify: true,
        submitOnMetaEnter: false,
    })

    useBlocker({
        // @todo Should be able to pass a blockerFn but does not work?
        blockerFn: () => window.confirm('hello?'),
        condition: locked,
    })

    const analyze = (newFeedback?: string): Promise<void> => {
        let totalFeedback = feedback

        if (typeof newFeedback !== 'undefined') {
            totalFeedback = [...feedback, newFeedback]
            setFeedback(totalFeedback)
        }

        return analyzeJobOfferMutation
            .mutateAsync({ offer: formProps.form.getFieldValue('vacancy'), feedback: totalFeedback })
            .then((result) => {
                setAnalyzeJobOfferResult(result)
            })
    }

    const sendAssistantMessage = (content?: string) => {
        let totalMessages = messages

        if (content) {
            totalMessages = [...messages, { role: 'user', content }]
            setMessages(totalMessages)
        }

        return assistantMessageMutation
            .mutateAsync({
                feedback,
                offer: formProps.form.getFieldValue('vacancy'),
                questions: analyzeJobOfferResult?.questions,
                messages: totalMessages,
                name: user!.name,
            })
            .then((result) => {
                if ('message' in result) {
                    setMessages((messages) => [...messages, { role: 'assistant', content: result.message }])
                    return
                }

                setMatch(result)
                nextStep()
            })
    }

    const unlock = () => {
        setLocked(false)
    }

    const steps: (StepProps & { key: CampaignCreateStep })[] = [
        {
            key: JOB_POSTING,
            title: t('Job posting'),
        },
        {
            key: TRY,
            title: t('Try me out'),
        },
        {
            key: FINISH,
            title: t('Finish'),
        },
    ]

    const current = Math.min(rest.current || 0, campaignCreateSteps.length - 1)
    const currentStep = steps[current].key

    const openStep = (step: number) => {
        if (step >= steps.length || step < 0) {
            return
        }

        rest.openStep(step)
    }

    const nextStep = () => openStep(current + 1)
    const previousStep = () => openStep(current - 1)

    const improve = () => {
        setMessages([])
        setMatch(undefined)
        setTryOut(false)
        openStep(0)
    }

    return (
        <CampaignCreateStepsContext.Provider
            value={{
                analyze,
                sendAssistantMessage,
                match,
                messages,
                isAnalyzing: analyzeJobOfferMutation.isPending,
                isSendingAssistantMessage: assistantMessageMutation.isPending,
                analyzeJobOfferResult,
                current,
                openStep,
                formProps,
                currentStep,
                previousStep,
                nextStep,
                steps,
                improve,
                tryOut,
                setTryOut,
                unlock,
            }}
        >
            {children}
        </CampaignCreateStepsContext.Provider>
    )
}

export const useCreateCampaignStepsContext = () => {
    const context = useContext(CampaignCreateStepsContext)

    if (!context) {
        throw new Error('useCreateContext must be used within a CreateContextProvider')
    }

    return context
}
