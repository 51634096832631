import { createFileRoute, Link } from '@tanstack/react-router'
import { Button, Card, Form, Input, Typography } from 'antd'
import React from 'react'
import { FormItem } from '../components/form-item'
import { useTranslation } from 'react-i18next'
import useForm from '../hooks/use-form'
import { useResetPassword } from '../user/hooks'
import { ResetInput } from '../user/input/reset-input'
import { z } from 'zod'

const { Text, Title } = Typography

export const Route = createFileRoute('/_guest/reset')({
    validateSearch: z.object({
        email: z.string(),
        token: z.string(),
    }),
    component: ResetComponent,
})

function ResetComponent() {
    const search = Route.useSearch()
    const { t } = useTranslation()
    const mutation = useResetPassword()

    const formProps = useForm({
        mutation,
    })

    return (
        <Card>
            <div className="mb-6">
                <Title level={2}>{t('Reset password')}</Title>
            </div>
            <Form<ResetInput>
                {...formProps}
                name="basic"
                initialValues={{
                    email: search.email,
                    token: search.token,
                }}
                layout="vertical"
                style={{ minWidth: 300 }}
                autoComplete="off"
            >
                <FormItem<ResetInput>
                    name="token"
                    hidden
                >
                    <Input name="token" />
                </FormItem>

                <FormItem<ResetInput>
                    label={t('Email')}
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        disabled
                        placeholder="john@example.com"
                    />
                </FormItem>

                <FormItem<ResetInput>
                    label={t('Password')}
                    name="password"
                    required
                >
                    <Input.Password
                        name="password"
                        placeholder="Password"
                    />
                </FormItem>
                <FormItem<ResetInput>
                    label={t('Confirm password')}
                    name="passwordConfirmation"
                    required
                >
                    <Input.Password
                        name="passwordConfirmation"
                        placeholder="Password"
                    />
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        loading={mutation.isPending}
                        block
                        type="primary"
                        htmlType="submit"
                        size="large"
                    >
                        {t('Reset')}
                    </Button>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t('Remember your password?')}</Text>
                        <Link
                            className="float-right text-primary"
                            to="/login"
                        >
                            {t('sign in, instead')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
