import { Button, notification } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForgotPassword } from '../../hooks'
import { User } from '../../user'
import { MailLink } from '../../../components/mail-link'

export type UserForgotButtonProps = {
    user: Pick<User, 'email' | 'blockedAt'>
}
export function UserForgotButton({ user }: UserForgotButtonProps) {
    const { t } = useTranslation()
    const mutation = useForgotPassword()

    return (
        <Button
            loading={mutation.isPending}
            disabled={!!user.blockedAt}
            onClick={() =>
                mutation.mutateAsync({ email: user.email }).then(() => {
                    notification.success({
                        message: t('Password reset sent to'),
                        description: <MailLink email={user.email} />,
                    })
                })
            }
        >
            {t('Forgot password')}
        </Button>
    )
}
