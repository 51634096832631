import { createFileRoute, Link } from '@tanstack/react-router'
import { Button, Card, Checkbox, Form, Input, Result, Typography, Radio } from 'antd'
import React from 'react'
import { FormItem } from '../components/form-item'
import { Trans, useTranslation } from 'react-i18next'
import useForm from '../hooks/use-form'
import { RegisterInput } from '../user/input/register-input'
import { useRegister } from '../user/hooks'
import i18n from 'i18next'
import { useAuth } from '../auth'

const { Text, Title } = Typography

export const Route = createFileRoute('/_guest/')({
    component: RegisterComponent,
})

function RegisterComponent() {
    const { t } = useTranslation()
    const { loginFromResponse } = useAuth()
    const register = useRegister()

    const formProps = useForm({
        mutation: register,
        onSuccess: loginFromResponse,
    })

    if (register.isSuccess) {
        return (
            <Result
                title={t('Registration successful')}
                subTitle={t(
                    'Before you will be able to login you will have to verify your email, check your email for instructions'
                )}
                status="success"
                extra={
                    <Link to="/login">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Login')}
                        </Button>
                    </Link>
                }
            />
        )
    }

    return (
        <Card>
            <div className="mb-6">
                <Title level={2}>{t('Create Account')}</Title>
            </div>
            <Form
                {...formProps}
                initialValues={{
                    locale: 'nl_NL',
                }}
                layout="vertical"
                style={{ minWidth: 300 }}
                autoComplete="off"
            >
                <FormItem<RegisterInput>
                    label={t('Language')}
                    name="locale"
                    required
                >
                    <Radio.Group
                        size="large"
                        onChange={(event) => i18n.changeLanguage(event.target.value)}
                    >
                        <Radio.Button value="nl_NL">{t('locales.nl_NL')}</Radio.Button>
                        <Radio.Button value="en_US">{t('locales.en_US')}</Radio.Button>
                    </Radio.Group>
                </FormItem>
                <FormItem<RegisterInput>
                    label={t('Name')}
                    name="name"
                    required
                >
                    <Input
                        name="name"
                        placeholder="John Doe"
                    />
                </FormItem>

                <FormItem<RegisterInput>
                    label={t('Email')}
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder="john@example.com"
                    />
                </FormItem>

                <FormItem<RegisterInput>
                    label={t('Password')}
                    name="password"
                    required
                >
                    <Input.Password
                        name="password"
                        placeholder="Password"
                    />
                </FormItem>
                <FormItem<RegisterInput>
                    label={t('Confirm password')}
                    name="passwordConfirmation"
                    required
                >
                    <Input.Password
                        name="passwordConfirmation"
                        placeholder="Password"
                    />
                </FormItem>

                <FormItem>
                    <FormItem<RegisterInput>
                        name="agreement"
                        valuePropName="checked"
                        className="mb-0"
                    >
                        <Checkbox name="agreement">
                            {/* refactor this for i18n */}
                            <Trans
                                defaults="By completing this registration, you hereby agree to our <br/><general>General Terms and Conditions</general>, and to our <privacy>Data privacy policy</privacy>"
                                components={{
                                    general: (
                                        <Link
                                            className="text-primary"
                                            to="/forgot"
                                        />
                                    ),
                                    privacy: (
                                        <Link
                                            className="text-primary"
                                            to="/forgot"
                                        />
                                    ),
                                }}
                            />
                        </Checkbox>
                    </FormItem>
                </FormItem>

                <FormItem className="mb-0">
                    <Button
                        loading={register.isPending}
                        block
                        type="primary"
                        htmlType="submit"
                        size="large"
                    >
                        {t('Register')}
                    </Button>
                    <div className="mt-5 justify-center flex space-x-1">
                        <Text>{t('If you already have an account, you can')}</Text>
                        <Link
                            className="float-right text-primary"
                            to="/login"
                        >
                            {t('sign in, instead')}
                        </Link>
                    </div>
                </FormItem>
            </Form>
        </Card>
    )
}
