import { createFileRoute, Outlet } from '@tanstack/react-router'
import React from 'react'
import { MainLayout } from '../components/layout/main-layout'

export const Route = createFileRoute('/_auth/_main')({
    component: AuthMain,
})

export function AuthMain() {
    return (
        <MainLayout>
            <Outlet />
        </MainLayout>
    )
}
