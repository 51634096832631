import { Button, Flex } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { TRY, useCreateCampaignStepsContext } from '../campaign-create-steps-context'
import { Title } from '../title'
import { Paragraph } from '../paragraph'
import { Chat } from '../../../../components/chat/chat'

export function TryStep() {
    const { t } = useTranslation()
    const { currentStep, nextStep, sendAssistantMessage, tryOut, setTryOut, isSendingAssistantMessage } =
        useCreateCampaignStepsContext()

    if (tryOut) {
        return <PracticeTryStep />
    }

    return (
        <div className={classNames({ hidden: currentStep !== TRY })}>
            <Title>{t('Try me out')}</Title>
            <Paragraph>
                {t(
                    "I'm ready to start contacting you, do you want to practice the conversation or shall I get started right away?"
                )}
            </Paragraph>
            <Flex gap="middle">
                <Button
                    loading={isSendingAssistantMessage}
                    size="large"
                    type="primary"
                    onClick={() => {
                        sendAssistantMessage().then(() => setTryOut(true))
                    }}
                >
                    {t('Try out')}
                </Button>
                <Button
                    loading={isSendingAssistantMessage}
                    size="large"
                    onClick={nextStep}
                >
                    {t('Skip, try out')}
                </Button>
            </Flex>
        </div>
    )
}

export function PracticeTryStep() {
    const { t } = useTranslation()
    const { currentStep, messages, sendAssistantMessage, isSendingAssistantMessage } = useCreateCampaignStepsContext()

    return (
        <div className={classNames({ hidden: currentStep !== TRY })}>
            <Title>{t('Try me out')}</Title>
            <Chat
                disabled={isSendingAssistantMessage}
                messages={messages}
                onMessageAdded={sendAssistantMessage}
            />
        </div>
    )
}
