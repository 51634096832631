import { UseMutationResult } from '@tanstack/react-query'
import { Form, FormProps } from 'antd'
import { addValidationOnError, onErrorNotifyAboutValidationError } from '../util'
import type { FormInstance } from 'antd/es/form/hooks/useForm'

// eslint-disable-next-line
type AnyMutationResult = UseMutationResult<any, any, any, any>

type Input<Mutation extends AnyMutationResult> = Parameters<Mutation['mutateAsync']>[0]

export type UseFormReturn<Mutation extends AnyMutationResult> = {
    form: FormInstance<Input<Mutation>>
    onFinish: FormProps<Input<Mutation>>['onFinish']
    onKeyDown: FormProps<Input<Mutation>>['onKeyDown']
}

export type UserFormProps<Mutation extends AnyMutationResult> = {
    mutation: Mutation
    onSuccess?: (value: NonNullable<Mutation['data']>) => unknown
    notify: boolean
    submitOnMetaEnter?: boolean
}

export default function useForm<Mutation extends AnyMutationResult>({
    mutation,
    onSuccess,
    notify = false,
    submitOnMetaEnter = true,
}: UserFormProps<Mutation>): UseFormReturn<Mutation> {
    const [form] = Form.useForm()

    return {
        form,
        onFinish(attributes) {
            mutation
                .mutateAsync(attributes)
                .then((value) => {
                    if (value === null || typeof value !== 'object') {
                        return
                    }

                    onSuccess?.(value)
                })
                .catch((error) => {
                    if (notify) {
                        onErrorNotifyAboutValidationError(error)
                    }

                    addValidationOnError(form)(error)
                })
        },
        onKeyDown: (event) => {
            if (submitOnMetaEnter && event.key === 'Enter' && event.metaKey) {
                form.submit()
            }
        },
    }
}
