import { Flex, Layout } from 'antd'
import React, { ReactNode } from 'react'
import logoLarge from '../../assets/logo-large.png'
import { useAuth } from '../../auth'
import { Container } from './container'
import { NotVerifiedAlert } from '../../user/components/not-verified-alert'

const { Content } = Layout

export type SimpleLayoutProps = { children: ReactNode }

export function SimpleLayout({ children }: SimpleLayoutProps) {
    const { user } = useAuth()

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                    >
                        <img
                            alt="log"
                            className="my-10"
                            width="169"
                            src={logoLarge}
                        />
                        {user && !user.emailVerifiedAt && <NotVerifiedAlert user={user} />}
                    </Flex>
                    {children}
                </Container>
            </Content>
        </Layout>
    )
}
