import { useUpdateCampaign } from '../hooks'
import { Link } from '@tanstack/react-router'
import { List, Switch, theme } from 'antd'
import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { CampaignBadge } from './campaign-badge'
import React, { MouseEventHandler } from 'react'
import { CampaignSearchResponse } from '../../api'

export type CampaignSearchListItemProps = {
    campaign: CampaignSearchResponse
    onClick: MouseEventHandler
}

export function CampaignSearchListItem({ campaign, onClick }: CampaignSearchListItemProps) {
    const { token } = theme.useToken()
    const updateCampaign = useUpdateCampaign(campaign.id)

    return (
        <Link
            to="/campaigns/$campaignId/overview"
            onClick={onClick}
            params={{ campaignId: campaign.id }}
        >
            <List.Item
                className="cursor-pointer"
                tabIndex={0}
                style={{
                    padding: `${token.paddingSM}px ${token.paddingMD}px`,
                }}
                actions={[
                    <Switch
                        onClick={(_, e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        onChange={() => {
                            updateCampaign.mutate({
                                status: campaign.status === 'active' ? 'inactive' : 'active',
                            })
                        }}
                        loading={updateCampaign.isPending}
                        checked={campaign.status === 'active'}
                        checkedChildren={<PauseOutlined />}
                        unCheckedChildren={<PlayCircleOutlined />}
                    />,
                ]}
            >
                <List.Item.Meta
                    avatar={<CampaignBadge status={campaign.status} />}
                    title={campaign.title}
                />
            </List.Item>
        </Link>
    )
}
