import { createFileRoute } from '@tanstack/react-router'
import { Badge, Card, Empty, Flex, Typography } from 'antd'
import React from 'react'
import { ConversationCard } from '../components/conversation-card'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_auth/_main/campaigns/$campaignId/conversations')({
    component: CampaignOverview,
})

function CampaignOverview() {
    return (
        <Flex gap="large">
            <ConversationColumn
                title="Positive"
                conversations={[
                    { name: 'Jamie Schouten', compatibility: 90 },
                    { name: 'Michael Wiersma', compatibility: 90 },
                ]}
            />
            <ConversationColumn
                title="Negative"
                conversations={[]}
            />
            <ConversationColumn
                title="Suspect"
                conversations={[
                    { name: 'Jamie Schouten', compatibility: 50 },
                    { name: 'Michael Wiersma', compatibility: 50 },
                ]}
            />
        </Flex>
    )
}

type Conversation = {
    name: string
    compatibility: number
}

function ConversationColumn({ title, conversations }: { title: string; conversations: Conversation[] }) {
    const { t } = useTranslation()

    return (
        <Card
            className="w-full"
            size="small"
        >
            <Typography.Title
                level={5}
                className="text-sm"
            >
                {title} {t('conversations')}
                {conversations.length > 0 && (
                    <Badge
                        count={conversations.length}
                        color="purple"
                        className="ml-2"
                    />
                )}
            </Typography.Title>
            {conversations.length === 0 && (
                <Empty
                    className="my-24"
                    description={t('No conversations')}
                />
            )}
            <Flex
                vertical
                gap="small"
            >
                {conversations.map((conversation) => {
                    return <ConversationCard {...conversation} />
                })}
            </Flex>
        </Card>
    )
}
