import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { createFileRoute } from '@tanstack/react-router'
import { Row, Col, Card, Statistic } from 'antd'
import React from 'react'
import { primary } from '../branding'
import { Container } from '../components/layout/container'

export const Route = createFileRoute('/_auth/_main/dashboard')({
    component: DashboardComponent,
})

function DashboardComponent() {
    return (
        <Container>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: primary }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
