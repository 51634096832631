import { Alert, AlertProps, Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '../user'
import { useResendVerification } from '../hooks'

export type NotVerifiedAlertProps = {
    user: Pick<User, 'email' | 'emailVerifiedAt' | 'id'>
} & AlertProps

export function NotVerifiedAlert({ user }: NotVerifiedAlertProps) {
    const { t } = useTranslation()
    const mutation = useResendVerification(user.id)
    return (
        <Alert
            message={t('You have not yet verified your email address {{ email }}', {
                email: user.email,
            })}
            type="info"
            showIcon
            action={
                <Button
                    loading={mutation.isPending}
                    onClick={() => mutation.mutate()}
                    size="small"
                    className="ml-4"
                >
                    {t('Resend verification')}
                </Button>
            }
        />
    )
}
