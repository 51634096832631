import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import React, { useEffect } from 'react'
import { useMe } from '../user/hooks'
import { useAuth } from '../auth'

export const Route = createFileRoute('/_auth')({
    beforeLoad: ({ context, location }) => {
        if (!context.auth.isAuthenticated) {
            throw redirect({
                to: '/login',
                search: {
                    redirect: location.href,
                },
            })
        }
    },
    component: AuthLayout,
})

export function AuthLayout() {
    const query = useMe()
    const { setUser } = useAuth()

    useEffect(() => {
        if (query.isSuccess) {
            setUser(query.data)
        }
    }, [query.isSuccess, query.data, setUser])

    return <Outlet />
}
