import { createFileRoute, Link } from '@tanstack/react-router'
import { Button, Flex, Result } from 'antd'
import React from 'react'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { useCampaigns } from '../campaign/hooks'
import { NetworkErrors } from '../components/network-errors'
import { CampaignList } from '../campaign/components/campaign-list'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_auth/_main/campaigns/')({
    component: CampaignsIndex,
})

function CampaignsIndex() {
    const query = useCampaigns()
    const { t } = useTranslation()

    if (query.isPending || query.isError) {
        return <NetworkErrors query={query} />
    }

    const hasCampaigns = query.data.data.length > 0

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: t('Campaigns'),
                    },
                ]}
            />
            <Flex
                justify="end"
                align="center"
                className="absolute right-[30px]"
            >
                {hasCampaigns && (
                    <Link to="/campaigns/create">
                        <Button
                            size="large"
                            type="primary"
                        >
                            {t('Add {{name}}', { name: t('Campaign') })}
                        </Button>
                    </Link>
                )}
            </Flex>
            {query.data.data.length === 0 ? (
                <Result
                    icon={<span className="-mt-6 block"></span>}
                    title={t("You don't have a campaign yet")}
                    subTitle={t(
                        'Get started by creating a campaign, a campaign will instruct eva to start conversations with candidates'
                    )}
                    extra={
                        <Link to="/campaigns/create">
                            <Button
                                size="large"
                                type="primary"
                            >
                                {t('Create {{name}}', t('Campaign'))}
                            </Button>
                        </Link>
                    }
                />
            ) : (
                <CampaignList campaigns={query.data.data} />
            )}
        </>
    )
}
