import { useMutation } from '@tanstack/react-query'
import { analyzeJobOffer, assistantMessage } from './api'
import { onErrorNotifyAboutValidationError } from '../util'

export function useAnalyzeJobOffer() {
    return useMutation({
        mutationFn: analyzeJobOffer,
        onError: onErrorNotifyAboutValidationError,
    })
}

export function useAssistantMessage() {
    return useMutation({
        mutationFn: assistantMessage,
        onError: onErrorNotifyAboutValidationError,
    })
}
