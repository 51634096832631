import { createFileRoute, Link, redirect } from '@tanstack/react-router'
import { Button, Flex, Typography } from 'antd'
import React from 'react'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { usePagination } from '../hooks/use-pagination'
import { searchQuery } from '../zod/pagination'
import { useTranslation } from 'react-i18next'
import { useUsers } from '../user/hooks'
import { UserTable } from '../user/components/user-table'
import { INDEX_USER } from '../user/permission'

export const Route = createFileRoute('/_auth/_main/users/')({
    beforeLoad: ({ context }) => {
        if (!context.auth.can(INDEX_USER)) {
            throw redirect({
                to: '/dashboard',
            })
        }
    },
    validateSearch: searchQuery,
    component: Users,
})

function Users() {
    const { t } = useTranslation()
    const search = Route.useSearch()

    const tableProps = usePagination(useUsers, { query: search })

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: t('Users'),
                    },
                ]}
            />
            <Flex
                justify="space-between"
                align="center"
                className="mb-4"
            >
                <Typography.Title
                    level={4}
                    className="mb-0"
                >
                    {t('Users')}
                </Typography.Title>
                <Link to="/users/create">
                    <Button
                        size="large"
                        type="primary"
                    >
                        {t('Add {{name}}', { name: t('User') })}
                    </Button>
                </Link>
            </Flex>
            <UserTable {...tableProps} />
        </>
    )
}
