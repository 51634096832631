import { LogoutOutlined, PieChartOutlined, SearchOutlined, UserOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { useMatchRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { Avatar, Button, Card, Flex, Layout, Menu, theme, Tooltip, Typography } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import logoLarge from '../../assets/logo-large.png'
import { useAuth } from '../../auth'
import { Container } from './container'
import { Search } from '../search'
import { INDEX_USER } from '../../user/permission'
import { NotVerifiedAlert } from '../../user/components/not-verified-alert'

const { Content } = Layout

export type MainLayoutProps = { children: ReactNode }

export function MainLayout({ children }: MainLayoutProps) {
    const { t } = useTranslation()
    const [searchOpen, setSearchOpen] = useState(false)
    const matchRoute = useMatchRoute()
    const navigate = useNavigate()
    const router = useRouter()

    const { user, logout, can } = useAuth()
    const { token } = theme.useToken()

    const menuItems = [
        {
            key: '/dashboard',
            icon: <PieChartOutlined />,
            label: t('Dashboard'),
            prefix: '/dashboard',
        },
        {
            key: '/campaigns',
            icon: <WhatsAppOutlined />,
            label: t('Campaigns'),
            prefix: '/campaigns',
        },
        can(INDEX_USER) && {
            key: '/users',
            icon: <UserOutlined />,
            label: t('Users'),
            prefix: '/users',
        },
    ].filter((items) => items !== false)

    const getSelectedKeys = () => {
        const matchedMenuItem = menuItems.find(({ prefix }) => {
            return matchRoute({ to: prefix, fuzzy: true }) !== false
        })

        return [matchedMenuItem?.key || '/dashboard']
    }

    const selectedKeys = getSelectedKeys()

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.target instanceof HTMLInputElement) {
                return
            }

            if (event.target instanceof HTMLTextAreaElement) {
                return
            }

            if (event.key === '/') {
                event.preventDefault()
                event.stopPropagation()
                setSearchOpen(true)
            }

            return
        })
    })

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                    >
                        <img
                            alt="log"
                            className="my-10"
                            width="169"
                            src={logoLarge}
                        />
                        {user && !user.emailVerifiedAt && <NotVerifiedAlert user={user} />}
                    </Flex>

                    <Card
                        className="shadow-card-sm"
                        styles={{
                            body: {
                                padding: '35px 30px',
                            },
                        }}
                    >
                        <Flex
                            justify="space-between"
                            align="center"
                            className="mb-[35px]"
                        >
                            <Flex
                                gap="middle"
                                align="center"
                            >
                                <Avatar
                                    size="large"
                                    src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${user?.id}`}
                                />

                                <Typography.Title
                                    className="font-medium mb-0"
                                    level={4}
                                >
                                    {t('Hello, {{name}}', { name: user?.name })}
                                </Typography.Title>

                                <div
                                    id="breadcrumb-portal"
                                    className="ml-5"
                                ></div>
                            </Flex>

                            <Flex
                                gap="middle"
                                align="center"
                            >
                                <Card
                                    size="small"
                                    className="min-w-[300px]"
                                    styles={{
                                        body: {
                                            padding: '5px 10px',
                                        },
                                    }}
                                    onClick={() => {
                                        return setSearchOpen(true)
                                    }}
                                >
                                    <span className="relative">
                                        <SearchOutlined />
                                        <Typography.Text
                                            className="ml-2"
                                            type="secondary"
                                        >
                                            {t('Search')}
                                        </Typography.Text>{' '}
                                        <Typography.Text
                                            className="float-right"
                                            keyboard
                                        >
                                            /
                                        </Typography.Text>
                                    </span>
                                </Card>
                                <Search
                                    open={searchOpen}
                                    onCancel={() => setSearchOpen(false)}
                                />

                                <Tooltip title="Sign out">
                                    <Button
                                        ghost
                                        style={{
                                            color: token.colorText,
                                        }}
                                        className="hover:text-primary"
                                        onClick={() => {
                                            logout()
                                            //   ????????????????
                                            setTimeout(() => {
                                                router.invalidate().finally(() => {
                                                    return navigate({ to: '/login' })
                                                })
                                            }, 0)
                                        }}
                                        icon={<LogoutOutlined />}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <Flex gap="large">
                            <Card
                                styles={{
                                    body: {
                                        padding: '35px 30px',
                                    },
                                }}
                                className="shadow-card min-w-[290px] "
                            >
                                <Menu
                                    selectedKeys={selectedKeys}
                                    onClick={({ key }) => navigate({ to: key })}
                                    items={menuItems}
                                    mode="inline"
                                />
                            </Card>
                            <Card
                                className="shadow-card w-full"
                                styles={{
                                    body: {
                                        padding: '35px 30px',
                                    },
                                }}
                            >
                                {children}
                            </Card>
                        </Flex>
                    </Card>
                </Container>
            </Content>
        </Layout>
    )
}
