import { UseMutationResult } from '@tanstack/react-query'
import { FormInstance, notification } from 'antd'
import { AxiosError } from 'axios'

export function timeAgo(date: Date) {
    const formatter = new Intl.RelativeTimeFormat('en')
    const ranges = [
        ['years', 3600 * 24 * 365],
        ['months', 3600 * 24 * 30],
        ['weeks', 3600 * 24 * 7],
        ['days', 3600 * 24],
        ['hours', 3600],
        ['minutes', 60],
        ['seconds', 1],
    ] as const
    const secondsElapsed = (date.getTime() - Date.now()) / 1000

    for (const [rangeType, rangeVal] of ranges) {
        if (rangeVal < Math.abs(secondsElapsed)) {
            const delta = secondsElapsed / rangeVal
            return formatter.format(Math.round(delta), rangeType)
        }
    }
}

export type ValidationError<T> = AxiosError<{
    errors: Record<keyof T, string>
}>

export function addValidationToForm(
    form: FormInstance,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: ValidationError<any>
) {
    const { errors } = error.response?.data || {}

    if (!errors) {
        throw error
    }

    const fields = Object.keys(errors).reduce<{ name: string; errors: string[] }[]>((carry, key) => {
        carry.push({
            name: key,
            errors: [errors[key]],
        })

        return carry
    }, [])

    form.setFields(fields as never)
}

export function addValidationOnError(form?: FormInstance) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: ValidationError<any>) => {
        if (!form) {
            return error
        }

        addValidationToForm(form, error)

        return error
    }
}

export function isValidationError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutation: UseMutationResult<any, any, any, any>
) {
    return mutation.isError && mutation.error instanceof AxiosError && mutation.error.response?.status === 422
}

export function upperFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function notifyAboutValidationError(error: string) {
    notification.warning({ message: error })
}

export function onErrorNotifyAboutValidationError(
    error: AxiosError<{ message: string; errors: { [key: string]: string[] } }>
) {
    if (error.response?.data) {
        notifyAboutValidationError(error.response.data.message)
    }
}
