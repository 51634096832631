import { Tag, TagProps } from 'antd'
import React from 'react'
import { User } from '../user'
import { useTranslation } from 'react-i18next'

export type RoleTagProps = {
    role: User['role']
} & TagProps

export function RoleTag({ role, ...props }: RoleTagProps) {
    const { t } = useTranslation()

    switch (role) {
        case 'customer':
            return <Tag {...props}>{t('roles.customer')}</Tag>
        case 'admin':
            return (
                <Tag
                    {...props}
                    color="pink"
                >
                    {t('roles.admin')}
                </Tag>
            )
    }
}
