import { axiosDefaults, baseURL, get, patch, post } from '../axios'
import { z } from 'zod'
import { user } from './user'
import { RegisterInput } from './input/register-input'
import { ResetInput } from './input/reset-input'
import { AuthenticateInput } from './input/authenticate-input'
import axios from 'axios'
import { loginResponse } from './login'
import { ForgotInput } from './input/forgot-input'

export * from './gen.api'

export const forgotPassword = (input: ForgotInput) => {
    return post(z.string(), '/forgot', input)
}

export const resetPassword = (input: ResetInput) => {
    return post(z.string(), '/reset', input)
}

export const register = (input: RegisterInput) => {
    return post(loginResponse, `/register`, input)
}

export const fetchMe = () => {
    return get(user, '/me')
}

export const verify = (email: string, signature: string, expires: number) => {
    return patch(z.string(), `/verify/${email}?expires=${expires}&signature=${signature}`)
}

export const login = (data: AuthenticateInput) => {
    return post(loginResponse, '/login', data)
}

export const refresh = (token: string) => {
    return axios
        .create(axiosDefaults)
        .get(baseURL + '/refresh', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => loginResponse.parse(response.data))
}
