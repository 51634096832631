import { MoreOutlined } from '@ant-design/icons'
import { Link } from '@tanstack/react-router'
import { Badge, Button, Card, Dropdown, Flex, List, MenuProps, theme, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { timeAgo } from '../../util'
import { Campaign } from '../campaign'
import { CampaignDeleteModal } from './campaign-delete-modal'
import { useTranslation } from 'react-i18next'

export type CampaignListProps = {
    campaigns: Campaign[]
}

export function CampaignList({ campaigns }: CampaignListProps) {
    const { token } = theme.useToken()
    const { t } = useTranslation()

    return (
        <>
            <Flex gap="middle">
                <ul
                    style={{
                        width: '100%',
                        padding: `${token.paddingMD}px ${token.paddingLG}px`,
                        display: 'grid',
                        gridGap: '1.25rem',
                        gridTemplateColumns: '[status] 8px [title] 1fr [created] 1fr [action] 32px',
                    }}
                >
                    <li></li>
                    <li>
                        <Typography.Text>{t('Title')}</Typography.Text>
                    </li>
                    <li>
                        <Typography.Text>{t('Created')}</Typography.Text>
                    </li>
                    <li></li>
                </ul>
            </Flex>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={campaigns}
                renderItem={(campaign) => (
                    <List.Item>
                        <Flex gap="middle">
                            <CampaignListRow campaign={campaign} />
                        </Flex>
                    </List.Item>
                )}
            />
        </>
    )
}

type CampaignListRowProps = {
    campaign: Campaign
}

function CampaignListRow({ campaign }: CampaignListRowProps) {
    const { t } = useTranslation()
    const { token } = theme.useToken()
    const [isDeleteOpen, setDeleteOpen] = useState(false)

    const dropdownItems: MenuProps['items'] = [
        {
            key: 'open',
            label: (
                <Link
                    to="/campaigns/$campaignId/conversations"
                    params={{ campaignId: campaign.id.toString() }}
                    className="w-full"
                >
                    {t('Open')}
                </Link>
            ),
        },
        {
            key: 'delete',
            danger: true,
            label: t('Delete'),
            onClick: (event) => {
                event.domEvent.preventDefault()
                event.domEvent.stopPropagation()
                setDeleteOpen(true)
            },
        },
    ]

    return (
        <>
            <Link
                to="/campaigns/$campaignId/conversations"
                params={{ campaignId: campaign.id.toString() }}
                className="w-full"
            >
                <Card
                    className="w-full shadow-sm"
                    size="small"
                >
                    <div
                        style={{
                            display: 'grid',
                            gridGap: '1.25rem',
                            alignItems: 'center',
                            gridTemplateColumns: '[status] 8px [title] 1fr [created] 1fr [action] 32px',
                        }}
                    >
                        <Flex
                            justify="center"
                            align="start"
                            vertical
                        >
                            <Tooltip
                                title={campaign.status === 'active' ? 'Conversations active' : 'Conversations inactive'}
                            >
                                <Badge
                                    className="lg-badge"
                                    color={campaign.status === 'active' ? 'green' : 'gray'}
                                />
                            </Tooltip>
                        </Flex>
                        <Flex
                            justify="center"
                            align="start"
                            vertical
                        >
                            <Typography.Title
                                className="text-primary mb-0"
                                level={5}
                            >
                                {campaign.title}
                            </Typography.Title>
                        </Flex>
                        <Flex
                            justify="center"
                            align="start"
                            vertical
                        >
                            <Typography.Text type="secondary">{timeAgo(campaign.createdAt)}</Typography.Text>
                        </Flex>
                        <Flex
                            justify="center"
                            align="start"
                            vertical
                        >
                            <Dropdown
                                trigger={['click']}
                                menu={{ items: dropdownItems }}
                                placement="bottom"
                                arrow
                            >
                                <Button
                                    ghost
                                    icon={
                                        <MoreOutlined
                                            rotate={90}
                                            style={{ color: token.colorText }}
                                            className="text-2xl hover:text-primary"
                                        />
                                    }
                                    onClick={(event) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                    }}
                                />
                            </Dropdown>
                        </Flex>
                    </div>
                </Card>
            </Link>
            <CampaignDeleteModal
                open={isDeleteOpen}
                close={() => {
                    setDeleteOpen(false)
                }}
                campaign={campaign}
            />
        </>
    )
}
