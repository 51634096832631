import * as React from 'react'
import { login as apiLogin } from './user/api'
import { can, canAny, User, user } from './user/user'
import { camelizeKeys } from 'humps'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { accessClaims, claims, LoginResponse } from './user/login'
import { AuthenticateInput } from './user/input/authenticate-input'
import i18n from 'i18next'
import { Locale } from './user/locale'
import { Permission } from './user/permission'

export interface AuthContext {
    isAuthenticated: boolean
    // eslint-disable-next-line
    login: UseMutationResult<any, any, any, any>
    logout: () => void
    user: User | null
    can: (permission: Permission | Permission[]) => boolean
    canAny: (permission: Permission[]) => boolean
    is: (userToCheck: Pick<User, 'id'>) => boolean
    setUser: (user: User) => void
    loginFromResponse: (response: LoginResponse) => void
}

const AuthContext = React.createContext<AuthContext | null>(null)
const userKey = 'auth.user'
const accessTokenKey = 'auth.access.token'
const refreshTokenKey = 'auth.refresh.token'

export function tokenClaims(token: string) {
    const claimsData = JSON.parse(atob(token.split('.')[1]))

    return claims.parse(camelizeKeys(claimsData))
}

export function tokenAccessClaims(token: string) {
    const claimsData = JSON.parse(atob(token.split('.')[1]))
    return accessClaims.parse(camelizeKeys(claimsData))
}

function getStoredUser() {
    const item = localStorage.getItem(userKey)

    if (item === null) {
        return item
    }

    return user.parse(JSON.parse(item))
}

export function storeUser(user: User | null) {
    if (user) {
        localStorage.setItem(userKey, JSON.stringify(user))
    } else {
        localStorage.removeItem(userKey)
    }
}

type TokenType = 'access' | 'refresh'

function storageKeyForTokenType(type: TokenType) {
    if (type === 'refresh') {
        return refreshTokenKey
    }

    return accessTokenKey
}

export function getToken(type: TokenType): string | null {
    const tokenValue = localStorage.getItem(storageKeyForTokenType(type))

    if (!tokenValue) {
        return null
    }

    return tokenValue
}

export function storeToken(token: string | null, type: TokenType) {
    if (token) {
        localStorage.setItem(storageKeyForTokenType(type), token)
    } else {
        localStorage.removeItem(storageKeyForTokenType(type))
    }
}

export function isTokenExpired(token: string) {
    const claims = tokenClaims(token)
    return Date.now() >= claims.exp * 1000
}

export function clearStorage() {
    storeToken(null, 'access')
    storeToken(null, 'refresh')
    storeUser(null)
}

export function defaultLocale() {
    return localStorage.getItem('defaultLocale') as null | Locale
}

export function storeDefaultLocale(locale: Locale) {
    localStorage.setItem('defaultLocale', locale)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [user, innerSetUser] = React.useState<User | null>(getStoredUser())
    const isAuthenticated = !!user

    const logout = React.useCallback(() => {
        clearStorage()
        innerSetUser(null)
    }, [])

    const setUser: AuthContext['setUser'] = (user) => {
        storeUser(user)
        innerSetUser(user)
        i18n.changeLanguage(user.locale)
        storeDefaultLocale(user.locale)
    }

    const loginFromResponse: AuthContext['loginFromResponse'] = (data) => {
        storeToken(data[0], 'access')
        storeToken(data[1], 'refresh')

        const { user } = tokenAccessClaims(data[0])
        setUser(user)
    }

    const login = useMutation({
        mutationFn: ({ email, password, remember }: AuthenticateInput) => {
            return apiLogin({
                email,
                password,
                remember,
            })
        },
        onSuccess: loginFromResponse,
    })

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                login,
                logout,
                is: (userToCheck) => {
                    return userToCheck.id === user?.id
                },
                can: (permission) => {
                    if (!user) {
                        return false
                    }
                    return can(user, permission)
                },
                canAny: (permissions) => {
                    if (!user) {
                        return false
                    }

                    return canAny(user, permissions)
                },
                setUser,
                loginFromResponse,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    const context = React.useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
