import { z } from 'zod'
import { ADMIN, CUSTOMER, role } from './role'
import { paginatedSearchQuery } from '../zod/pagination'
import { locale } from './locale'
import {
    BLOCK_USER,
    CREATE_USER,
    INDEX_CAMPAIGN,
    INDEX_USER,
    Permission,
    SHOW_USER,
    STORE_CAMPAIGN,
    UNBLOCK_USER,
    UNSCOPED,
    UPDATE_CAMPAIGN,
    UPDATE_USER,
} from './permission'

export const user = z.object({
    id: z.string(),
    name: z.string(),
    email: z.string(),
    role,
    locale,
    emailVerifiedAt: z.coerce.date().nullable(),
    blockedAt: z.coerce.date().nullable(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
})

export type User = z.infer<typeof user>

export const userQuery = paginatedSearchQuery({
    filters: z.object({
        name: z.string().array().optional(),
        email: z.string().array().optional(),
        blocked: z.boolean().array().optional(),
        role: role.array().optional(),
    }),
    sort: z.enum(['name', '-name', 'email', '-email', 'role', '-role']),
})

export type UserQuery = z.infer<typeof userQuery>

export function can(user?: User, permission: Permission | Permission[] = []): boolean {
    const userPermissions = user?.role ? authorization[user?.role] : []

    if (Array.isArray(permission)) {
        return permission.filter((permission) => userPermissions.includes(permission)).length === permission.length
    }

    return userPermissions.includes(permission) || false
}

export function canAny(user?: User, permissions: Permission[] = []): boolean {
    const userPermissions = user?.role ? authorization[user?.role] : []

    return permissions.filter((permission) => userPermissions.includes(permission)).length > 0
}

const authorization = {
    [ADMIN]: [
        UNSCOPED,

        INDEX_USER,
        SHOW_USER,
        CREATE_USER,
        UPDATE_USER,
        BLOCK_USER,
        UNBLOCK_USER,

        STORE_CAMPAIGN,
        INDEX_CAMPAIGN,
        UPDATE_CAMPAIGN,
    ],
    [CUSTOMER]: [STORE_CAMPAIGN, INDEX_CAMPAIGN, UPDATE_CAMPAIGN, UPDATE_USER],
}
