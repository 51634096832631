import { Button, Flex, Input } from 'antd'
import { FormItem } from '../../../../components/form-item'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreInput } from '../../../input/store-input'
import classNames from 'classnames'
import { JOB_POSTING, useCreateCampaignStepsContext } from '../campaign-create-steps-context'
import { ChatMessage } from '../../../../components/chat/message'
import { Title } from '../title'
import { Paragraph } from '../paragraph'

export function JobPostingStep() {
    const { analyzeJobOfferResult } = useCreateCampaignStepsContext()

    if (analyzeJobOfferResult) {
        return <ImproveOffer />
    }

    return <InitialOffer />
}

function InitialOffer() {
    const { t } = useTranslation()
    const { currentStep, analyze, isAnalyzing } = useCreateCampaignStepsContext()

    return (
        <div className={classNames({ hidden: currentStep !== JOB_POSTING })}>
            <Title>{t('Job posting')}</Title>
            <Paragraph type="secondary">
                {t(
                    'I’m Evaa, and I’ll be reaching out to candidates on your behalf Before I can get started, I need some information from you'
                )}
            </Paragraph>
            <Paragraph>{t('Would you be able to send me the contents of the job posting?')}</Paragraph>
            <FormItem<StoreInput> name="vacancy">
                <Input.TextArea
                    placeholder={t('Enter your message or attach your document')}
                    autoSize={{ minRows: 2, maxRows: 30 }}
                />
            </FormItem>
            <Button
                loading={isAnalyzing}
                size="large"
                type="primary"
                onClick={() => {
                    analyze()
                }}
            >
                {t('Submit')}
            </Button>
        </div>
    )
}

function ImproveOffer() {
    const { t } = useTranslation()
    const { analyze, isAnalyzing, currentStep, nextStep, analyzeJobOfferResult } = useCreateCampaignStepsContext()

    const [improvement, setImprovement] = useState('')

    return (
        <div className={classNames({ hidden: currentStep !== JOB_POSTING })}>
            <Title>{t('Job posting')}</Title>
            <Paragraph>
                {t('I have read through the job posting, and am going to open contact with an {{tone}} tone', {
                    tone: analyzeJobOfferResult?.toneOfVoice,
                })}
            </Paragraph>
            <Paragraph>{t('I’m going to ask candidates questions like')}</Paragraph>
            <Flex
                vertical
                gap="middle"
                className="mb-2 max-h-96 overflow-y-scroll"
            >
                {analyzeJobOfferResult?.questions.map((question, index) => {
                    return (
                        <ChatMessage
                            key={index}
                            role="assistant"
                            content={question}
                        />
                    )
                })}
            </Flex>
            <Paragraph>
                {t(
                    'Are there any questions you think are important or is there information I need to know that wasn’t in the job posting?'
                )}
            </Paragraph>
            <FormItem>
                <Input.TextArea
                    disabled={isAnalyzing}
                    value={improvement}
                    onChange={(event) => setImprovement(event.currentTarget.value)}
                    placeholder={t('Enter your improvement')}
                    autoSize={{ minRows: 2, maxRows: 30 }}
                />
            </FormItem>
            <Flex justify="space-between">
                <Button
                    disabled={!improvement}
                    loading={isAnalyzing}
                    size="large"
                    onClick={() => {
                        analyze(improvement).then(() => {
                            setImprovement('')
                            console.log('clear')
                        })
                    }}
                >
                    {t('Add improvements')}
                </Button>

                <Button
                    loading={isAnalyzing}
                    size="large"
                    type="primary"
                    onClick={nextStep}
                >
                    {t('Looks good, lets continue')}
                </Button>
            </Flex>
        </div>
    )
}
