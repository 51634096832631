import React from 'react'
import { Container } from '../../../components/layout/container'
import { Card, Flex, Form, Steps } from 'antd'
import { JobPostingStep } from './steps/job-posting-step'
import { TryStep } from './steps/try-step'
import { FinishStep } from './steps/finish-step'
import { useCreateCampaignStepsContext } from './campaign-create-steps-context'

export function CampaignCreateSteps() {
    const { steps, formProps, current, openStep } = useCreateCampaignStepsContext()

    return (
        <Container className="max-w-[900px]">
            <Card
                className="rounded-3xl overflow-hidden"
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
            >
                <Flex className="bg-primary">
                    <Form
                        {...formProps}
                        initialValues={{
                            title: '',
                            vacancy: '',
                        }}
                        layout="vertical"
                        className="w-full p-12 bg-white"
                    >
                        <JobPostingStep />
                        <TryStep />
                        <FinishStep />
                    </Form>
                    <div className="min-w-[330px] pt-24 p-16">
                        <Steps
                            current={current}
                            progressDot
                            direction="vertical"
                            className="text-white onboard-steps"
                            items={steps}
                        />
                    </div>
                </Flex>
            </Card>
        </Container>
    )
}
