import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { routeTree } from './routeTree.gen'
import './i18n'
import { AuthProvider, useAuth } from './auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider, notification } from 'antd'
import { primary, secondary } from './branding'
import { AxiosError, isAxiosError } from 'axios'
import * as Sentry from '@sentry/react'

if (!import.meta.env.DEV) {
    Sentry.init({
        dsn: 'https://74cb91639577421d26f21ca24d251692@o4507782361251840.ingest.de.sentry.io/4507793438867536',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost'],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

// Set up a Router instance
const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    context: {
        auth: undefined!,
    },
})

// Register things for typesafety
declare module '@tanstack/react-router' {
    interface Register {
        defaultError: AxiosError
        router: typeof router
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error) => {
                if (isAxiosError(error) && error.response?.status === 404) {
                    return false
                }

                return failureCount <= 3
            },
        },
    },
})

notification.config({
    placement: 'top',
    duration: 5,
})

function InnerApp() {
    const auth = useAuth()
    return (
        <RouterProvider
            router={router}
            context={{ auth }}
        />
    )
}

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ConfigProvider
                    theme={{
                        // algorithm: theme.darkAlgorithm,
                        token: {
                            fontWeightStrong: 300,
                            fontFamily: 'Poppins',
                            colorPrimary: primary,
                            colorLink: primary,
                            borderRadius: 12,
                        },
                        components: {
                            Layout: {
                                triggerBg: secondary,
                                siderBg: secondary,
                            },
                        },
                    }}
                >
                    <InnerApp />
                </ConfigProvider>
            </AuthProvider>
        </QueryClientProvider>
    )
}

const rootElement = document.getElementById('app')!

if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(<App />)
}
