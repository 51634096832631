import { keepPreviousData, QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    blockUser,
    fetchMe,
    fetchUser,
    fetchUsers,
    forgotPassword,
    register,
    resendVerification,
    resetPassword,
    storeUser,
    unblockUser,
    updateUser,
    verify,
} from './api'
import { User, UserQuery } from './user'
import { ResetInput } from './input/reset-input'
import { SearchResponse } from '../api'
import { onErrorNotifyAboutValidationError } from '../util'
import { notification } from 'antd'
import { StoreInput } from './input/store-input'
import i18n, { t } from 'i18next'
import { UpdateInput } from './input/update-input'
import { useAuth } from '../auth'

export function useMe() {
    return useQuery({
        queryFn: fetchMe,
        queryKey: ['me'],
        refetchInterval: 6e4,
        refetchOnWindowFocus: 'always',
    })
}

export function useUsers({
    query,
}: {
    query?: UserQuery
} = {}) {
    return useQuery({
        queryFn: () => fetchUsers({ query }),
        queryKey: ['users', query],
        placeholderData: keepPreviousData,
    })
}

export function useUser(userId: string) {
    return useQuery({
        queryFn: () => {
            return fetchUser(userId)
        },
        queryKey: ['users', userId],
    })
}

export function useStoreUser() {
    return useMutation({
        mutationFn: (input: StoreInput) => storeUser(input),
        onSuccess(user) {
            notification.success({
                message: t('{{name}} has been created', { name: user.name }),
                description: t('An email with instructions will be sent shortly to {{email}}.', { email: user.email }),
                duration: 10,
            })
        },
    })
}

export function useUnblockUser(userId: string) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return unblockUser(userId)
        },
        onSuccess(user) {
            setUsersQueriesData(queryClient, user)
            setSearchQueriesData(queryClient, 'users', user)
            notification.success({
                message: t('You have unblocked {{name}}', { name: user.name }),
                description: t('{{name}} can now log in again', { name: user.name }),
            })
        },
    })
}

export function useBlockUser(userId: string) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return blockUser(userId)
        },
        onSuccess(user) {
            setUsersQueriesData(queryClient, user)
            setSearchQueriesData(queryClient, 'users', user)
            notification.success({
                message: t('You have blocked {{name}}', { name: user.name }),
                description: t('From now on {{name}}, wil no longer be able to login', { name: user.name }),
            })
        },
    })
}

export function useUpdateUser(userId: string) {
    const queryClient = useQueryClient()
    const { is } = useAuth()
    return useMutation({
        mutationFn: (input: UpdateInput) => updateUser(userId, input),
        async onSuccess(user) {
            if (is(user)) {
                await i18n.changeLanguage(user.locale)
            }

            setUsersQueriesData(queryClient, user)
            setSearchQueriesData(queryClient, 'users', user)
            notification.success({
                message: t('{{name}} updated', { name: user.name }),
            })
        },
    })
}
export function useForgotPassword() {
    return useMutation({
        mutationFn: forgotPassword,
    })
}

export function useResetPassword() {
    return useMutation({
        mutationFn: (data: ResetInput) => resetPassword(data),
    })
}

export function useRegister() {
    return useMutation({
        mutationFn: register,
    })
}

export function useResendVerification(userId: string) {
    return useMutation({
        mutationFn: () => resendVerification(userId),
        onSuccess: () =>
            notification.success({
                message: t('Verification email sent'),
            }),
        onError: onErrorNotifyAboutValidationError,
    })
}

export function useVerify(email: string, signature: string, expires: number) {
    return useQuery({
        queryFn: () => verify(email, signature, expires),
        queryKey: ['verify'],
        retry: 0,
    })
}

function setSearchQueriesData<K extends keyof SearchResponse, T extends Partial<Required<SearchResponse>[K][0]>>(
    queryClient: QueryClient,
    key: K,
    patch: T
) {
    queryClient.setQueriesData(
        {
            queryKey: ['search'],
        },
        (data: undefined | SearchResponse) => {
            if (!data || !data[key]) {
                return data
            }

            data[key] = data[key].map((user) => {
                if (user.id === patch.id) {
                    return {
                        ...user,
                        ...patch,
                        // eslint-disable-next-line
                    } as any
                }

                // eslint-disable-next-line
                return user as any
            })

            return data
        }
    )
}

function setUsersQueriesData(queryClient: QueryClient, patch: Partial<User>) {
    queryClient.setQueriesData(
        {
            queryKey: ['users'],
        },
        (data: undefined | User | Awaited<ReturnType<typeof fetchUsers>>) => {
            if (!data) {
                return data
            }

            if ('id' in data) {
                if (data.id === patch.id) {
                    return {
                        ...data,
                        ...patch,
                    }
                }

                return data
            }

            data.data = data.data.map((user) => {
                if (user.id === patch.id) {
                    return {
                        ...user,
                        ...patch,
                    }
                }

                return user
            })

            return data
        }
    )
}
