import { createFileRoute } from '@tanstack/react-router'
import React, { useState } from 'react'
import { CampaignCreateSteps } from '../campaign/components/create/campaign-create-steps'
import { CampaignCreateStepsContextProvider } from '../campaign/components/create/campaign-create-steps-context'

export const Route = createFileRoute('/_auth/_simple/campaigns/create')({
    component: CampaignsCreate,
})

function CampaignsCreate() {
    const [current, setCurrent] = useState(0)

    return (
        <CampaignCreateStepsContextProvider
            current={current}
            openStep={setCurrent}
        >
            <CampaignCreateSteps />
        </CampaignCreateStepsContextProvider>
    )
}
