import { createFileRoute, Link, redirect, useNavigate } from '@tanstack/react-router'
import { Button, Flex, Form, Input, Typography } from 'antd'
import React from 'react'
import { PortaledBreadcrumb } from '../components/portaled-breadcrumb'
import { useTranslation } from 'react-i18next'
import { useStoreUser } from '../user/hooks'
import { CREATE_USER } from '../user/permission'
import { FormItem } from '../components/form-item'
import useForm from '../hooks/use-form'
import { LocaleRadio } from '../user/components/locale-radio'
import { RoleRadio } from '../user/components/role-radio'
import { StoreInput } from '../user/input/store-input'
import { useAuth } from '../auth'

export const Route = createFileRoute('/_auth/_main/users/create')({
    beforeLoad: ({ context }) => {
        if (!context.auth.can(CREATE_USER)) {
            throw redirect({
                to: '/dashboard',
            })
        }
    },
    component: CreateUser,
})

function CreateUser() {
    const { t } = useTranslation()
    const mutation = useStoreUser()
    const { user } = useAuth()
    const navigate = useNavigate()

    const formProps = useForm({
        mutation,
        onSuccess(user) {
            navigate({
                to: `/users/${user.id}`,
            })
        },
    })

    return (
        <>
            <PortaledBreadcrumb
                items={[
                    {
                        title: <Link to="/users">{t('Users')}</Link>,
                    },
                    {
                        title: t('Add'),
                    },
                ]}
            />
            <Flex
                justify="space-between"
                align="center"
                className="mb-4"
            >
                <Typography.Title
                    level={4}
                    className="mb-0"
                >
                    {t('Add {{name}}', { name: t('User') })}
                </Typography.Title>
            </Flex>
            <Form
                layout="vertical"
                className="max-w-96"
                initialValues={{
                    locale: user?.locale,
                }}
                {...formProps}
            >
                <FormItem<StoreInput>
                    label={t('Role')}
                    name="role"
                    required
                >
                    <RoleRadio />
                </FormItem>

                <FormItem<StoreInput>
                    label={t('Name')}
                    name="name"
                    required
                >
                    <Input
                        name="name"
                        placeholder="John Doe"
                    />
                </FormItem>

                <FormItem<StoreInput>
                    label={t('Email')}
                    name="email"
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder="john@example.com"
                    />
                </FormItem>
                <FormItem<StoreInput>
                    label={t('Language')}
                    name="locale"
                    required
                >
                    <LocaleRadio />
                </FormItem>
                <Button
                    loading={mutation.isPending}
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    {t('Add')}
                </Button>
            </Form>
        </>
    )
}
